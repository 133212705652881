/*
 *
 * B2B constants
 *
 */

export const LOG_IN = 'app/B2B/LOG_IN';
export const LOG_IN_SUCCESS = 'app/B2B/LOG_IN_SUCCESS';

export const GET_PROFILE_DATA = 'app/B2B/GET_PROFILE_DATA';
export const SET_PROFILE_DATA = 'app/B2B/SET_PROFILE_DATA';
export const UPDATE_PROFILE_DATA = 'app/B2B/UPDATE_PROFILE_DATA';

export const GET_LIBRARIES = 'app/B2B/GET_LIBRARIES';
export const SET_LIBRARIES = 'app/B2B/SET_LIBRARIES';

export const GET_LIBRARY = 'app/B2B/GET_LIBRARY';
export const SET_LIBRARY = 'app/B2B/SET_LIBRARY';
export const CREATE_LIBRARY = 'app/B2B/CREATE_LIBRARY';
export const UPDATE_LIBRARY = 'app/B2B/UPDATE_LIBRARY';
export const DELETE_LIBRARY = 'app/B2B/DELETE_LIBRARY';
export const CHANGE_LIBRARY_SUBSCRIPTION = 'app/B2B/CHANGE_LIBRARY_SUBSCRIPTION';
export const CANCEL_LIBRARY_SUBSCRIPTION = 'app/B2B/CANCEL_LIBRARY_SUBSCRIPTION';
export const GENERATE_LIBRARY_LINK = 'app/B2B/GENERATE_LIBRARY_LINK';
export const CREATE_LIBRARY_REFERENT = 'app/B2B/CREATE_LIBRARY_REFERENT';
export const UPDATE_LIBRARY_REFERENT = 'app/B2B/UPDATE_LIBRARY_REFERENT';
export const DELETE_LIBRARY_REFERENT = 'app/B2B/DELETE_LIBRARY_REFERENT';
