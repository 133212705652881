/*
 *
 * Auth constants
 *
 */

export const CREATE_ACCOUNT = 'app/Auth/CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'app/Auth/CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'app/Auth/CREATE_ACCOUNT_ERROR';
export const LOGIN_ACCOUNT = 'app/Auth/LOGIN_ACCOUNT';
export const LOGIN_ACCOUNT_SUCCESS = 'app/Auth/LOGIN_ACCOUNT_SUCCESS';
export const LOGIN_ACCOUNT_ERROR = 'app/Auth/LOGIN_ACCOUNT_ERROR';
export const CREATE_ACCOUNT_GOOGLE = 'app/Auth/CREATE_ACCOUNT_GOOGLE';
export const GET_GOOGLE_TOKEN = 'app/Auth/GET_GOOGLE_TOKEN';
export const SET_USER_INFO = 'app/Auth/SET_USER_INFO';
export const LOGIN_GOOGLE = 'app/Auth/LOGIN_GOOGLE';
export const LOGOUT = 'app/Auth/LOGOUT';
export const GET_USER_BASIC_DATA = 'app/Auth/GET_USER_BASIC_DATA';
export const GET_USER_BASIC_DATA_SUCCESS = 'app/Auth/GET_USER_BASIC_DATA_SUCCESS';
export const GET_STUDENT_BASIC_DATA_SUCCESS = 'app/Auth/GET_STUDENT_BASIC_DATA_SUCCESS';
export const GET_USER_BASIC_DATA_ERROR = 'app/Auth/GET_USER_BASIC_DATA_ERROR';
export const UPDATE_USER_BASIC_DATA = 'app/Auth/UPDATE_USER_BASIC_DATA';
export const UPDATE_USER_BASIC_DATA_SUCCESS = 'app/Auth/UPDATE_USER_BASIC_DATA_SUCCESS';
export const UPDATE_USER_BASIC_DATA_ERROR = 'app/Auth/UPDATE_USER_BASIC_DATA_ERROR';
export const UPDATE_USER_COMPANY_DATA = 'app/Auth/UPDATE_USER_COMPANY_DATA';
export const UPDATE_USER_COMPANY_DATA_ERROR = 'app/Auth/UPDATE_USER_COMPANY_DATA_ERROR';
export const UPDATE_PASSWORD = 'app/Auth/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'app/Auth/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'app/Auth/UPDATE_PASSWORD_ERROR';
export const UPDATE_EMAIL = 'app/Auth/UPDATE_EMAIL';
export const UPDATE_AVATAR = 'app/Auth/UPDATE_AVATAR';
export const UPDATE_AVATAR_SUCCESS = 'app/Auth/UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_ERROR = 'app/Auth/UPDATE_AVATAR_ERROR';
export const SEND_PASSWORD_RESET = 'app/Auth/SEND_PASSWORD_RESET';
export const RESEND_EMAIL_VERIFICATION = 'app/Auth/RESEND_EMAIL_VERIFICATION';
export const CONFIRM_NEW_PASSWORD = 'app/Auth/CONFIRM_NEW_PASSWORD';
export const GET_USER_CATEGORIES = 'app/Auth/GET_USER_CATEGORIES';
export const GET_USER_CATEGORIES_SUCCESS = 'app/Auth/GET_USER_CATEGORIES_SUCCESS';
export const USER_ATTACH_CATEGORY = 'USER_ATTACH_CATEGORY';
export const USER_DETACH_CATEGORY = 'USER_DETACH_CATEGORY';
export const SET_QUESTIONNAIRE = 'app/Auth/SET_QUESTIONNAIRE';
export const ANSWER_TO_QUESTIONNAIRE = 'app/Auth/ANSWER_TO_QUESTIONNAIRE';
// Stripe
export const CREATE_STRIPE_ACCOUNT = 'app/Auth/CREATE_STRIPE_ACCOUNT';
export const GET_FIRST_STEP_STRIPE_VERIFICATION = 'app/Auth/GET_FIRST_STEP_STRIPE_VERIFICATION';
export const GET_SECOND_STEP_STRIPE_VERIFICATION = 'app/Auth/GET_SECOND_STEP_STRIPE_VERIFICATION';
export const BALANCE_WITHDRAW = 'app/Auth/BALANCE_WITHDRAW';
export const DELETE_ACCOUNT = 'app/Auth/DELETE_ACCOUNT';
export const GET_STRIPE_CONNECTED_ACCOUNT_LINK = 'app/Auth/GET_STRIPE_CONNECTED_ACCOUNT_LINK';
export const SET_STRIPE_CONNECTED_ACCOUNT_LINK = 'app/Auth/SET_STRIPE_CONNECTED_ACCOUNT_LINK';
// Paypal
export const GET_PAYPAL_ACCOUNT_INFO = 'app/Auth/GET_PAYPAL_ACCOUNT_INFO';
export const SET_PAYPAL_ACCOUNT_INFO = 'app/Auth/SET_PAYPAL_ACCOUNT_INFO';
export const VERIFY_PAYPAL_ACCOUNT = 'app/Auth/VERIFY_PAYPAL_ACCOUNT';
export const PAYPAL_WITHDRAW = 'app/Auth/PAYPAL_WITHDRAW';
// affiliation
export const GET_AFFILIATE_HOST = 'app/Auth/GET_AFFILIATE_HOST';
export const SET_AFFILIATE_HOST = 'app/Auth/SET_AFFILIATE_HOST';
