/*
 *
 * Subscription constants
 *
 */

export const GET_SUBSCRIPTION_TYPES = 'app/Subscription/GET_SUBSCRIPTION_TYPES';
export const GET_SUBSCRIPTION_TYPES_SUCCESS = 'app/Subscription/GET_SUBSCRIPTION_TYPES_SUCCESS';
export const GET_STUDENT_CURRENT_SUBSCRIPTION_PLAN =
  'app/Subscription/GET_STUDENT_CURRENT_SUBSCRIPTION_PLAN';
export const SET_STUDENT_CURRENT_SUBSCRIPTION_PLAN =
  'app/Subscription/SET_STUDENT_CURRENT_SUBSCRIPTION_PLAN';
export const GET_STUDENT_ACTIVE_SUBSCRIPTION_PLAN =
  'app/Subscription/GET_STUDENT_ACTIVE_SUBSCRIPTION_PLAN';
export const SET_STUDENT_ACTIVE_SUBSCRIPTION_PLAN =
  'app/Subscription/SET_STUDENT_ACTIVE_SUBSCRIPTION_PLAN';
export const GET_PAYMENT_INFO = 'app/Subscription/GET_PAYMENT_INFO';
export const GET_STUDENT_CARD_INFO = 'app/Subscription/GET_STUDENT_CARD_INFO';
export const SET_STUDENT_CARD_INFO = 'app/Subscription/SET_STUDENT_CARD_INFO';
export const GET_PAYMENT_METHODS = 'app/Subscription/GET_PAYMENT_METHODS';
export const SET_PAYMENT_METHODS = 'app/Subscription/SET_PAYMENT_METHODS';
export const POST_STUDENT_CREATE_SUBSCRIPTION = 'app/Subscription/POST_STUDENT_CREATE_SUBSCRIPTION';
export const POST_STUDENT_SELECT_SUBSCRIPTION = 'app/Subscription/POST_STUDENT_SELECT_SUBSCRIPTION';
export const APPLY_STUDENT_SUBSCRIPTION = 'app/Subscription/APPLY_STUDENT_SUBSCRIPTION';
export const STUDENT_SELECT_SUBSCRIPTION = 'app/Subscription/STUDENT_SELECT_SUBSCRIPTION';
export const POST_STUDENT_CANCEL_SUBSCRIPTION = 'app/Subscription/POST_STUDENT_CANCEL_SUBSCRIPTION';
export const POST_STUDENT_CHANGE_SUBSCRIPTION = 'app/Subscription/POST_STUDENT_CHANGE_SUBSCRIPTION';
export const POST_STUDENT_DELETE_SUBSCRIPTION = 'app/Subscription/POST_STUDENT_DELETE_SUBSCRIPTION';
export const POST_STUDENT_CANCEL_SCHEDULED_SUBSCRIPTION =
  'app/Subscription/POST_STUDENT_CANCEL_SCHEDULED_SUBSCRIPTION';
export const POST_STUDENT_CREATE_SETUP_INTENT = 'app/Subscription/POST_STUDENT_CREATE_SETUP_INTENT';
export const SET_STUDENT_SETUP_INTENT_SECRET = 'app/Subscription/SET_STUDENT_SETUP_INTENT_SECRET';
export const POST_STUDENT_CREATE_PAYMENT_INTENT =
  'app/Subscription/POST_STUDENT_CREATE_PAYMENT_INTENT';
export const POST_STUDENT_CREATE_PAYMENT_INTENT_SUCCESS =
  'app/Subscription/POST_STUDENT_CREATE_PAYMENT_SUCCESS';
export const SET_STUDENT_DEFAULT_PAYMENT_METHOD =
  'app/Subscription/SET_STUDENT_DEFAULT_PAYMENT_METHOD';
export const POST_STUDENT_ADD_STUDENT_CARD = 'app/Subscription/POST_STUDENT_ADD_STUDENT_CARD';
export const GET_STUDENT_INVOICE_DETAILS = 'app/Subscription/GET_STUDENT_INVOICE_DETAILS';
export const GET_STUDENT_DEFAULT_CARD_INFO = 'app/Subscription/GET_STUDENT_DEFAULT_CARD_INFO';
export const GET_INVOICES = 'app/Subscription/GET_INVOICES';
export const SET_INVOICES = 'app/Subscription/SET_INVOICES';
export const DELETE_PAYMENT_METHOD = 'app/Subscription/DELETE_PAYMENT_METHOD';
export const GET_CANCEL_SUBSCRIPTION_QUESTIONNAIRE =
  'app/Subscription/GET_CANCEL_SUBSCRIPTION_QUESTIONNAIRE';
export const SET_CANCEL_SUBSCRIPTION_QUESTIONNAIRE =
  'app/Subscription/SET_CANCEL_SUBSCRIPTION_QUESTIONNAIRE';
export const ANSWER_CANCEL_SUBSCRIPTION_QUESTIONNAIRE =
  'app/Subscription/ANSWER_CANCEL_SUBSCRIPTION_QUESTIONNAIRE';
